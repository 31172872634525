<template>
  <div>
    <div class="pdfok" id="pdfok" v-if="pdfok"></div>
    <div id="printMe" style=" width: 21cm; margin: 0 auto;border: 0;">
      <InnerPage v-for="(item, index) in dataInfo" :info="item" :key="index" :idx="index"></InnerPage>
    </div>
    <el-button v-if="false" v-print="'#printMe'" type="primary"
      style="position: fixed;top: 0;left: 0;z-index: 999;">打印</el-button>

  </div>
</template>

<script>

import InnerPage from './com_tch_sxsp/innerPageNew'


export default {
  name: 'export',
  components: {
    InnerPage,
  },
  data() {
    return {
      pdfok: false,
      show_dev: true,
      show_dev1: true,
      printObj: {
        id: 'printMe',
        popTitle: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      userid: '',
      cover: {},
      rolls: [],
      stu_kaohe: [],
      stu_lianxi: [],
      shixi_anli: [{}],
      youxiu_shixisheng: [],
      stu_weiji: [],
      fanxiao: [],
      dataInfo: {},
      vstList: [],
    }
  },
  mounted() {
    let class_id = this.getParams('id')//班级id
    console.log('class_id:', class_id)
    let pmid = this.getParams('pmid')
    let is_all = this.getParams('is_all')
    this.getAllPickerVal(pmid).then(rr => {
      this.getDataList(class_id, pmid, rr, is_all)
    })
  },
  methods: {
    getAllPickerVal(pmid) {
      return new Promise(resolve => {
        let arrForm = [], arrState = [];
        //巡访形式&&巡访情况
        this.$http.post("/api/sys_param_list", { param_type: "'VISIT_FORM','VISIT_SITUATION'", pmid: pmid }).then((res) => {
          res.data && res.data.map((item, index, arr) => {
            if (item.param_type == 'VISIT_FORM') {
              arrForm.push(item)
            }
            if (item.param_type == 'VISIT_SITUATION') {
              arrState.push(item)
            }
          })
          resolve({
            arrForm, arrState,
          })
        })
      })
    },

    //过滤特殊字符, 校验所有输入域是否含有特殊符号
    stripscript(s) {
      var mdash = '&mdash;'
      var nbsp = '&nbsp;'
      var quot = '&quot;'
      var ldquo = '&ldquo;'
      var rdquo = '&rdquo;'
      var amp = '&amp;'
      var lt = '&lt;'
      var gt = '&gt;'


      var rs = ''
      rs = s.replace(new RegExp(mdash, 'g'), '-');
      rs = rs.replace(new RegExp(nbsp, 'g'), '');
      rs = rs.replace(new RegExp(quot, 'g'), '"');
      rs = rs.replace(new RegExp(ldquo, 'g'), '”');
      rs = rs.replace(new RegExp(rdquo, 'g'), '“');
      rs = rs.replace(new RegExp(amp, 'g'), '&');
      rs = rs.replace(new RegExp(lt, 'g'), '<');
      rs = rs.replace(new RegExp(gt, 'g'), '>');
      return rs
    },
    getDataList(class_id, pmid, rr, is_all) {
      let _this = this
      let rData = []
      let arrForm = rr ? rr.arrForm : []
      this.$http.post('/api/export_sxsq_pdf', {
        class_ids: class_id,
        pmid: parseInt(pmid),
        // uid: this.getParams('id'),
        biye: "",
        is_all: is_all == 1 ? 1 : 0,
      }).then(res => {
        if (res.data) {
          rData = res.data
          rData && rData.length > 0 && rData.map(a => {
            if (pmid == 226) {
              if (a.class_name && (a.class_name.indexOf('护理') > -1 || a.class_name.indexOf('助产') > -1)) {
                a.mentor_name = '尹健'
                a.mentor_tel = '15850597280'
              }
              if (a.class_name && (a.class_name.indexOf('护理') < 0 && a.class_name.indexOf('助产') < 0)) {
                a.mentor_name = '郑秀明'
                a.mentor_tel = '13951824274'
              }
            }

            if (pmid == 226 && a.enters) {
              a.enters.map(e => {
                e.enter_info = '公立'
                e.salary = '2000元以下'
                if (e.firm_name && (e.firm_name.indexOf('明基') > -1 || e.firm_name.indexOf('同仁') > -1 || e.firm_name.indexOf('泰康') > -1 || e.firm_name.indexOf('紫金') > -1)) {
                  e.enter_info = '民营'
                }
                if (a.class_name && (a.class_name.indexOf('护理') > -1 || a.class_name.indexOf('助产') > -1)) {
                  e.department = '护理部'
                  e.job = '护理'
                  e.yx_wd = '未定'
                }
                if (a.class_name && a.class_name.indexOf('康复') > -1) {
                  e.department = '康复科'
                  e.job = '康复'
                }
                if (a.class_name && a.class_name.indexOf('老年') > -1) {
                  e.department = '养老护理部'
                  e.job = '护理员'
                  e.enter_info = '民营'
                }
                if (a.class_name && (a.class_name.indexOf('医电') > -1 || a.class_name.indexOf('影像') > -1 || a.class_name.indexOf('放射') > -1)) {
                  e.department = '影像科'
                  e.job = '技士'
                }
              })
            }

          })
          console.log(222222, pmid,rData)

          this.dataInfo = rData
        }
        this.$nextTick(() => {
          setTimeout(() => { _this.pdfok = true }, 500)
        })
      })
    },

    getParams(name) {
      let n_url = window.location.href
      n_url = n_url.replace('/#', '')
      if (n_url.indexOf('?') > 0) {
        n_url = n_url.split('?')[1]
      }
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      var r = n_url.match(reg)
      if (r != null) return unescape(r[2])
      return null
    },
    //分割数组 arr：原数组  num：分割后每个数组里的数据条数
    util_spliceArrByN(arr, num) {
      var _arr = []
      while (arr.length) {
        _arr.push(arr.splice(0, num))
      }
      return _arr
    },
    //数组按某属性分组
    groupBy(array, f) {
      let groups = {}
      array.forEach(function (o) {
        let group = JSON.stringify(f(o))
        groups[group] = groups[group] || []
        groups[group].push(o)
      })
      return Object.keys(groups).map(function (group) {
        return groups[group]
      })
    }
  }
}
</script>
<style lang="less" type="text/less">
@import "/public/css/pdf_default";
</style>
